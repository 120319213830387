<template>
  <!--Content start-->
    <main id="content">
      <div class="container">
        <div class="row">
          <!-- top section -->
          <div class="col-12 mt-05">
              <!-- Big grid 4 -->
            <div class="row featured-4 mb-5">
              <!--Start big box news-->
              <div class="col-md-6 pt-05 pb-05 pe-md-05">
                <div class="card card-full text-white overflow zoom" v-for="publish in caricatur.slice(0,1)" :key="publish.id">
                  <div class="height-ratio image-wrapper">
                    <!--thumbnail-->
                    <a class="pointer container__img-holder">
                      <img  class="img-fluid myImg" v-lazy="publish.caricature"  data-src="" :alt="publish.title_ar.substring(0,70)">
                    </a>
                    <div class="position-absolute px-3 pb-3 pt-0 b-0 w-100 bg-lg-shadow">
                      <!--category-->
                      <!-- <a class="badge bg-primary text-white" href="#">Fashion</a> -->
                      <!--title-->
                      <a class="pointer">
                        <h2 class="h3 h2-sm h1-md display-4-lg fw-bold heading-letter-spacing text-white arabic-kufi">{{ publish.title_ar.substring(0,70)+".." }}</h2>
                      </a>
                      <!-- author & date -->
                      <div class="news-meta d-none d-md-block">
                        <span class="news-author"> <a class="text-white fw-bold arabic-kufi" > تاق برس</a></span>
                        <span class="news-date arabic-kufi">{{ publish.upload_date.substring(0,10) }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <!--End big box news-->
              <!--Start box news-->
              <div class="col-md-6 pt-05 pb-05 ps-md-05">
                <div class="row newsbox">
                  <!-- news box -->
                  <article class="col-6" v-for="publish in caricatur.slice(1,4)" :key="publish.id">
                    <div class="card card-full text-white overflow zoom">
                      <div class="height-ratio image-wrapper">
                        <!--thumbnail-->
                        <a class="pointer container__img-holder">
                          <img  class="img-fluid myImg" v-lazy="publish.caricature"  data-src="" alt="">
                        </a>
                        <!-- category & title -->
                        <div class="position-absolute px-3 pb-3 pt-0 b-0 w-100 bg-shadow">
                          <!-- <a class="p-1 badge bg-primary text-white" href="#">Automotive</a> -->
                          <a  class="pointer">
                            <h3 class="h5 h3-sm h5-md text-light my-1 arabic-kufi">{{ publish.title_ar.substring(0,70)+".." }}</h3>
                          </a>
                        </div>
                      </div>
                    </div>
                  </article>
                </div>
              </div>
              <!--End box news-->
            </div>
            <!--End Big grid 4-->
          </div>
          <!--start left column-->
          <div class="col-md-8" v-if="showMore">
            <!--Block start-->
            <div class="block-area">
              <!--block title-->
              <div class="block-title-11">
                <h4 class="h5 center-backline arabic-kufi">
                  <span class="bg-themes">مزيد من كاركاتير</span>
                </h4>
              </div>
              <!-- block-content -->
              <div class="row">
                <!--post start-->
                <article class="col-6 col-lg-4" v-for="publish in caricatur.slice(4,13)" :key="publish.id">
                  <div class="card card-full hover-a mb-4">
                    <!--thumbnail-->
                    <div class="ratio_230-129 image-wrapper">
                      <a class="pointer container__img-holder">
                        <img  class="img-fluid myImg" v-lazy="publish.caricature"  data-src="" :alt="publish.title_ar.substring(0,70)">
                      </a>
                    </div>
                    <div class="card-body">
                      <!-- author, date & comments -->
                      <div class="card-text mb-2 text-muted small">
                        <span class="d-none d-sm-inline me-1">
                          <a class="fw-bold arabic-kufi" >تاق برس</a>
                        </span>
                        <time datetime="2019-10-21 arabic-kufi">{{ publish.upload_date.substring(0,10) }}</time>
                      </div>
                      <!--title-->
                      <h2 class="card-title h5 h4-sm h6-md h5-lg arabic-kufi"><a href="#">{{ publish.title_ar.substring(0,70)+".." }}</a></h2>
                    </div>
                  </div>
                </article>
                <!--post start-->
              </div>
              <!-- end block content -->
            </div>
            <!--End Block-->
          </div>
          <!--start right sidebar-->
          <aside class="col-md-4 end-sidebar-lg" v-if="showMore">
            <div class="sticky">
              <!-- block start -->
              <aside class="block-area">
                <!--block title-->
                <div class="block-title-11">
                  <h4 class="h5 center-backline">
                    <span class="bg-themes arabic-kufi">كاركاتير </span>
                  </h4>
                </div>
                <!-- block-content -->
                <div class="row">
                  <!--post start-->
                  <article class="col-6 col-md-12 col-lg-6" v-for="publish in caricatur.slice(13,22)" :key="publish.id">
                    <div class="card card-full hover-a mb-4">
                      <!--thumbnail-->
                      <div class="ratio_230-129 image-wrapper">
                        <a class="pointer container__img-holder">
                          <img  class="img-fluid myImg" v-lazy="publish.caricature"  data-src="" :alt="publish.title_ar.substring(0,70)">
                        </a>
                      </div>
                      <div class="card-body">
                        <!-- date & comments -->
                        <div class="card-text mb-2 text-muted small">
                          <time datetime="2019-10-21 arabic-kufi">{{ publish.upload_date.substring(0,10) }}</time>
                          <!-- <span title="5 comment" class="float-end">
                            <span class="icon-comments"></span>
                          </span> -->
                        </div>
                        <!--title-->
                        <h2 class="card-title h5 h4-sm h5-md h6-lg arabic-kufi"><a >{{ publish.title_ar.substring(0,70)+".." }}</a></h2>
                      </div>
                    </div>
                  </article>
                  <!--post start-->
                </div>
                <!-- end block content -->
              </aside>
              <!-- end block -->
            </div>
          </aside>
          
            <div class="d-grid arabic-kufi" v-else>
              <form action="https://dsplp.sd.zain.com/?p=3885574267" method="POST">
                <button class="btn btn-primary" style="width: 100%;background-color: #62b5b5 !important;border-color: #118e8e !important;">مزيد من الكاركاتير</button>
              </form>
               <!-- <router-link class="btn btn-primary" to="/NewSub">مزيد من الكاركاتير</router-link> -->
            </div>
          <!-- <div id="myModal" role="dialog" class="modal fade">
          <span class="close" onclick="document.getElementById('myModal').style.display='none'">&times;</span>
            <img class="modal-content" src="" id="img01">
            <div id="caption"></div>
        </div> -->
        </div>
      
      </div>
      <div class="img-popup">
  <img src="" alt="Popup Image">
  <div class="close-btn">
    <div class="bar"></div>
    <div class="bar"></div>
  </div>
</div>
    </main>
    <!-- The Modal -->
        
<!-- 
        <div class="modal fade" role="dialog" id="imgmodal">
    <div class="modal-dialog">
        <div class="modal-content"></div>          
                    <img class="img-responsive" src="" id="show-img">         
        </div>
    </div> -->
    <br>
</template>

<script>
import $ from 'jquery'
import { HTTP } from '@/Api/http-common';
import { HTTPDSP } from '@/Api/http-dsp';
import { useRouter, useRoute } from "vue-router";
import { ref } from '@vue/reactivity';
import { watchEffect } from '@vue/runtime-core';
import { useCookie } from 'vue-cookie-next'
import { useMeta } from 'vue-meta'
export default {
   name: 'Caricatures',
   async setup() {
      useMeta({ title: 'كاركاتير' })
      const router = useRouter();
      const route = useRoute();
      const cookie = useCookie()
      const caricatur = ref([]);
      const showMore = ref(true);
      // const getCaricatur = async () => {
        try {
          await HTTP.get(`getCaricature.php?LIMIT=30&OFFSET=0`).then((res) => {
            caricatur.value = res.data.Caricature; 
          });
        } catch (err) {
          console.log(err);
        }
      // }
      // onMounted(() => {
      //     getCaricatur();
      // });
      watchEffect(() => {
            if (cookie.isCookieAvailable("status") && cookie.isCookieAvailable("msisdn")) {
                HTTPDSP.post(`DSPCheckLogin.php?msisdn=`+cookie.getCookie("msisdn")).then((res) => {
                    if (res.data.status == 1 ) {
                        return;
                    } else {
                        cookie.removeCookie("minutes");
                        cookie.removeCookie("msisdn");
                        cookie.removeCookie("status");
                    }
                });
                showMore.value = true;
            } else {
                showMore.value = false;
            }
        });  

        return {
           caricatur, showMore
        };
   },
    mounted() {
        $(document).ready(function() {

  // required elements
  var imgPopup = $('.img-popup');
  var imgCont  = $('.container__img-holder');
  var popupImage = $('.img-popup img');
  var closeBtn = $('.close-btn');

  // handle events
  imgCont.on('click', function() {
    var img_src = $(this).children('img').attr('src');
    imgPopup.children('img').attr('src', img_src);
    imgPopup.addClass('opened');
  });

  $(imgPopup, closeBtn).on('click', function() {
    imgPopup.removeClass('opened');
    imgPopup.children('img').attr('src', '');
  });

  popupImage.on('click', function(e) {
    e.stopPropagation();
  });
  
});
    },
}
</script>

<style scoped>

.pointer {
  cursor: pointer;
}

/* Popup Styling */
.img-popup {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0,0,0,0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
}

.img-popup img {
  max-width: 600px;
  width: 100%;
  opacity: 0;
  transform: translateY(-100px);
  -webkit-transform: translateY(-100px);
  -moz-transform: translateY(-100px);
  -ms-transform: translateY(-100px);
  -o-transform: translateY(-100px);
}

.close-btn {
  width: 35px;
  height: 30px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  position: absolute;
  top: 30px;
  right: 30px;
  cursor: pointer;
}

.close-btn .bar {
  height: 4px;
  background: #fff;
}

.close-btn .bar:nth-child(1) {
  transform: rotate(45deg);
}

.close-btn .bar:nth-child(2) {
  transform: translateY(-4px) rotate(-45deg);
}

.opened {
  display: flex;
}

.opened img {
  animation: animatepopup 1s ease-in-out .8s;
  -webkit-animation: animatepopup .3s ease-in-out forwards;
}

@keyframes animatepopup {

  to {
    opacity: 1;
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    -o-transform: translateY(0);
  }

}

@media screen and (max-width: 880px) {

  .container .container__img-holder:nth-child(3n+1) {
    margin-left: 16px;
  }

}
</style>